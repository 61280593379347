import { useEffect, useState } from "react";
import "swiper/css";
import beispielessenLg from "../../../assets/images/beispielessen_lg.jpg";
import beispielessenLow from "../../../assets/images/beispielessen_low.jpg";
import beispielessenMd from "../../../assets/images/beispielessen_md.jpg";
import beispielessenSm from "../../../assets/images/beispielessen_sm.jpg";
import deliLogo from "../../../assets/images/m1deli_logo.svg";
import HttpBackend from "i18next-http-backend";
import i18next from "i18next";

type Props = {
  lng: string;
};

const SlideM1Deli = ({ lng }: Props) => {
  const [t, setT] = useState(() => (key: string) => key);

  useEffect(() => {
    i18next
      .use(HttpBackend)
      .init({
        lng,
        fallbackLng: "de",
        debug: false,
        backend: {
          loadPath: "/locales/{{lng}}/translation.json",
        },
      })
      .then(() => {
        setT(() => i18next.t.bind(i18next));
      });
  }, [lng]);

  const handleEventSlideClick = (url: string) => {
    window.location.href = url;
  };

  return (
    <div
      className="swiper-slide cursor-pointer"
      onClick={() => handleEventSlideClick(`/${lng}/deli`)}
    >
      <div className="mx-auto w-5/6 space-x-4 my-2 md:inline-flex flex-wrap">
        <a href={`/${lng}/deli`}>
          <div className="flex-2 relative">
            <img
              src={beispielessenLow.src}
              srcSet={`${beispielessenSm.src} 480w,
                       ${beispielessenMd.src} 768w,
                       ${beispielessenLg.src} 1200w`}
              sizes="(max-width: 600px) 480px,
                     (max-width: 960px) 768px,
                     1200px"
              alt="M1 Deli Lunch"
              data-src={beispielessenLg.src}
              className="lazyload w-6/8 h-auto overflow-hidden"
              loading="lazy"
              style={{ filter: "blur(10px)", transition: "filter 0.3s ease" }}
            />
          </div>
          <div className="absolute inset-x-0 bottom-1/2 transform translate-y-1/2 w-full h-2/5 flex items-center justify-end pr-8">
            <div className="w-4/5 md:w-2/5 bg-white bg-opacity-70 p-4">
              <div className="text-xl md:text-4xl dark:text-black font-semibold text-right">
                <p>
                  <img src={deliLogo.src} alt="M1 Deli Logo" />
                </p>
                <p className="text-xl md:text-2xl w-full">
                  {t("hero.deliSlide.line1")}
                </p>
                <p className="text-xl md:text-2xl w-full">
                  {t("hero.deliSlide.line2")}
                </p>
                <p className="text-xl md:text-2xl w-full">
                  {t("hero.deliSlide.lunch")}
                </p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default SlideM1Deli;
