import "swiper/css";
import frontLarge from "../../../assets/images/store_front_full_size.jpg"; // High-res
import frontLow from "../../../assets/images/store_front_full_size_low.jpg";
import frontMedium from "../../../assets/images/store_front_full_size_medium.jpg";
import frontSmall from "../../../assets/images/store_front_full_size_small.jpg";

import { useEffect } from "react";
import frontDayLarge from "../../../assets/images/store_front_full_size_day.jpg"; // High-res
import frontDayLow from "../../../assets/images/store_front_full_size_day_low.jpg";
import frontDayMedium from "../../../assets/images/store_front_full_size_day_medium.jpg";
import frontDaySmall from "../../../assets/images/store_front_full_size_day_small.jpg";

type Props = {
  line1: string;
  line2: string;
  line3: string;
  line4: string;
};

const SlideStoreFront = ({ line1, line2, line3, line4 }: Props) => {
  useEffect(() => {
    const lazyImages =
      document.querySelectorAll<HTMLImageElement>("img.lazyload");

    lazyImages.forEach((img) => {
      const highResSrc = img.getAttribute("data-src");

      if (highResSrc) {
        const highResImg = new Image();
        highResImg.src = highResSrc;

        highResImg.onload = () => {
          img.src = highResSrc;
          img.style.filter = "blur(0)";
        };
      }
    });
  }, []);

  return (
    <div className="swiper-slide">
      <div className="mx-auto w-5/6 space-x-4 my-2 md:inline-flex flex-wrap relative">
        {/* Dark Mode Image */}
        <div className="flex-2 md:flex-1 hidden dark:block">
          <img
            src={frontLow.src}
            srcSet={`${frontSmall.src} 480w, ${frontMedium.src} 768w, ${frontLarge.src} 1200w`}
            sizes="(max-width: 600px) 480px, (max-width: 960px) 768px, 1200px"
            data-src={frontLarge.src}
            alt="MicroOffice One Eilbek Storefront - Dark Mode"
            className="lazyload"
            loading="lazy"
            style={{ filter: "blur(10px)", transition: "filter 0.3s ease" }}
          />
        </div>

        {/* Light Mode Image */}
        <div className="flex-2 md:flex-1 dark:hidden">
          <img
            src={frontDayLow.src}
            srcSet={`${frontDaySmall.src} 480w, ${frontDayMedium.src} 768w, ${frontDayLarge.src} 1200w`}
            sizes="(max-width: 600px) 480px, (max-width: 960px) 768px, 1200px"
            data-src={frontDayLarge.src}
            alt="MicroOffice One Eilbek Storefront - Light Mode"
            className="lazyload"
            loading="lazy"
            style={{ filter: "blur(10px)", transition: "filter 0.3s ease" }}
          />
        </div>

        {/* Text Content */}
        <div className="absolute inset-x-0 top-1/2 -translate-y-1/2 w-full h-2/5 flex items-center justify-end pr-8 md:flex-1 text-4xl lg:text-5xl font-semibold dark:text-white text-right">
          <div className="w-4/5 md:w-2/5 md:bg-transparent md:bg-opacity-100 bg-white bg-opacity-70 p-4">
            {[line1, line2, line3, line4].map((line, index) => (
              <p key={index} className="my-4">
                {line}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideStoreFront;
