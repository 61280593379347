import { useEffect, useState } from "react";
import Swiper from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "../../assets/styles/swiper-custom.css";
import SlideStoreFront from "./slides/SlideStoreFront";
import SlideStoreFrontBollerbattle from "./slides/SlideStoreFrontBollerbattle";
import HttpBackend from "i18next-http-backend";
import i18next from "i18next";
import SlideM1Deli from "./slides/SlideM1Deli";

type Props = {
  lng: string;
};

const HeroSlider = ({
  lng,
}: Props) => {

  const [t, setT] = useState(() => (key: string) => key);

  useEffect(() => {
    i18next
      .use(HttpBackend)
      .init({
        lng: lng,
        fallbackLng: "de",
        debug: false,
        backend: {
          loadPath: "/locales/{{lng}}/translation.json",
        },
      })
      .then(() => {
        setT(() => i18next.t.bind(i18next));
      });
  }, [lng]);

  useEffect(() => {
    const initSwiper = () => {
      const swiper = new Swiper(".swiper-container", {
        modules: [Autoplay, Pagination, Navigation],
        spaceBetween: 30,
        pagination: { clickable: true },
        autoplay: { delay: 5000 },
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });

      // Cleanup Swiper instance on component unmount
      return () => {
        if (swiper) swiper.destroy(true, true);
      };
    };

    setTimeout(initSwiper, 0);
  }, []);

  return (
    <section className="w-full overflow-hidden">
      <div className="mx-auto w-full lg:px-24 max-w-7xl md:px-12 items-center px-8 py-14 selection:bg-slate-500">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            <SlideStoreFront
              line1={t("hero.slide1.line1")}
              line2={t("hero.slide1.line2")}
              line3={t("hero.slide1.line3")}
              line4={t("hero.slide1.line4")}
            />
            <SlideM1Deli lng={lng} />
            <SlideStoreFrontBollerbattle
              title={t("hero.slide2.line1")}
              line1={t("hero.slide2.line2")}
              line2={t("hero.slide2.line3")}
              from={t("hero.slide2.from")}
              url={`/${lng}/events`}
            />
          </div>

          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </div>
      </div>
    </section>
  );
};

export default HeroSlider;
